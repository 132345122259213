<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col>
          <items-list :greenhouse="greenhouse" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex'
  import ItemsList from '@/components/Customers/Edit/Tabs/GreenhouseTab/ItemsList'
  export default {
    name: 'GreenhouseTab',
    components: {
      ItemsList,
    },
    props: {
      customer: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      loading: true,
      greenhouse: [],
    }),
    mounted () {
      this.loading = true
      this.loadGreenhouse({ customer_id: this.customer.id })
        .then(res => {
          this.greenhouse = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      ...mapActions({
        loadGreenhouse: 'customer/greenhouse/loadGreenhouse',
      }),
    },
  }
</script>
