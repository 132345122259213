<template>
  <v-list-item>
    <template v-slot:default="{ active }">
      <v-list-item-content>
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-img :src="item.current_stage.image_url" />
            </v-col>
            <v-col
              cols="12"
              sm="9"
              md="9"
            >
              <v-row>
                <v-col cols="12">
                  <div>
                    {{ item.name }}
                  </div>
                </v-col>

                <v-col cols="12">
                  {{ item.total_current_price }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon
          color="grey"
        >
          mdi-eye
        </v-icon>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
<script>
  export default {
    name: 'Item',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
  }
</script>
