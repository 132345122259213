<template>
  <v-list two-line>
    <v-list-item-group
      v-if="greenhouse.length"
      active-class="pink--text"
      multiple
    >
      <item
        v-for="item in greenhouse"
        :key="item.id"
        :item="item"
      />
    </v-list-item-group>
    <template v-else>
      <v-list-item>
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-container class="py-0">
              <v-row>
                {{ $t('greenhouse_empty_message') }}
              </v-row>
            </v-container>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
  import Item from '@/components/Customers/Edit/Tabs/GreenhouseTab/Item'
  export default {
    name: 'ItemsList',
    components: {
      Item,
    },
    props: {
      greenhouse: {
        type: Array,
        required: true,
      },
    },
  }
</script>
