<template>
  <v-dialog
    v-model="opened"
    persistent
    max-width="600px"
  >
    <material-card
      dark
      :title="$t('view_document')"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-img :src="item.file_url" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <a
                target="_blank"
                :href="item.file_url"
              >{{ $t('open_document') }}</a>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="declineDocument"
        >
          {{ $t('decline') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="approveDocument"
        >
          {{ $t('verify') }}
        </v-btn>
      </v-card-actions>
    </material-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ViewDocumentModal',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        opened: false,
      }
    },
    methods: {
      ...mapActions({
        approve: 'customer/documents/approve',
        decline: 'customer/documents/decline',
      }),
      approveDocument () {
        this.approve({
          document_id: this.item.id,
        })
          .then(result => {
            this.$emit('approved', result)
          })
          .finally(() => {
            this.closeDialog()
          })
      },
      declineDocument () {
        this.decline({
          document_id: this.item.id,
        })
          .then(result => {
            this.$emit('declined', result)
          })
          .finally(() => {
            this.closeDialog()
          })
      },
      openDialog () {
        this.opened = true
      },
      closeDialog () {
        this.opened = false
      },
    },
  }
</script>
