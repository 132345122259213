<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-model="newCustomerData.first_name"
            :label="$t('firstname_field')"
            maxlength="20"
            required
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-model="newCustomerData.last_name"
            :label="$t('lastname_field')"
            maxlength="20"
            required
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newCustomerData.email"
            :label="$t('email_field')"
            required
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-select
            v-model="newCustomerData.country"
            :items="countries"
            item-text="label"
            item-value="value"
            :label="$t('country_field')"
            persistent-hint
            single-line
            required
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-select
            v-model="newCustomerData.region"
            :items="selectedCountry.regions"
            item-text="label"
            item-value="value"
            :label="$t('region_field')"
            persistent-hint
            single-line
            required
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newCustomerData.phone_number"
            :label="$t('phone_number_field')"
            required
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'ProfileTab',
    props: {
      customer: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      newCustomerData: {},
      countries: [
        {
          label: 'Україна',
          value: 'ukraine',
          regions: [
            { label: 'АР Крим', value: 'crimea' },
            { label: 'Вінницька', value: 'vinnitsia' },
            { label: 'Волинська', value: 'volin' },
            { label: 'Дніпропетровська', value: 'dnipropetrovsk' },
            { label: 'Донецька', value: 'donetsk' },
            { label: 'Житомирська', value: 'zhitomir' },
            { label: 'Закарпатська', value: 'zakarpattia' },
            { label: 'Запорізька', value: 'zaporizzha' },
            { label: 'Івано-Франківська', value: 'iveno-frankivsk' },
            { label: 'Київська', value: 'kyiv' },
            { label: 'Кіровоградська', value: 'kirovograd' },
            { label: 'Луганська', value: 'lugansk' },
            { label: 'Львівська', value: 'lviv' },
            { label: 'Миколаївська', value: 'mikolaiv' },
            { label: 'Одеська', value: 'odesa' },
            { label: 'Полтавська', value: 'poltava' },
            { label: 'Рівненська', value: 'rivne' },
            { label: 'Сумська', value: 'sumi' },
            { label: 'Тернопільська', value: 'ternopil' },
            { label: 'Харківська', value: 'harkiv' },
            { label: 'Херсонська', value: 'herson' },
            { label: 'Хмельницька', value: 'khmelnitsk' },
            { label: 'Черкаська', value: 'cherkasi' },
            { label: 'Чернівецька', value: 'cernivtsi' },
            { label: 'Чернігівська', value: 'chernihiv' },
            { label: 'місто Севастополь', value: 'sevastopol_region' },
          ],
        },
        {
          label: 'Polska',
          value: 'poland',
          regions: [
            { label: 'Łódź', value: 'lodz' },
            { label: 'Świętokrzyskie', value: 'swietokrzyskie' },
            { label: 'Greater Poland', value: 'greater-poland' },
            { label: 'Kuyavian-Pomeranian', value: 'kuyavian-pomeranian' },
            { label: 'Lesser Poland', value: 'lesser-poland' },
            { label: 'Lublin', value: 'lublin' },
            { label: 'Lubusz', value: 'lubusz' },
            { label: 'Masovian', value: 'masovian' },
            { label: 'Opole', value: 'opole' },
            { label: 'Podlachian', value: 'podlachian' },
            { label: 'Pomeranian', value: 'pomeranian' },
            { label: 'Silesian', value: 'silesian' },
            { label: 'Subcarpathian', value: 'subcarpathian' },
            { label: 'Warmian-Masurian', value: 'warmian-masurian' },
            { label: 'West Pomeranian', value: 'west-pomeranian' },
          ],
        },
        {
          label: 'Deutschland',
          value: 'germany',
          regions: [
            { label: 'Baden-Württemberg', value: 'baden-wurttemberg' },
            { label: 'Bayern', value: 'bavaria' },
            { label: 'Berlin', value: 'berlin' },
            { label: 'Brandenburg', value: 'brandenburg' },
            { label: 'Bremen', value: 'bremen' },
            { label: 'Hamburg', value: 'hamburg' },
            { label: 'Hessen', value: 'hesse' },
            { label: 'Niedersachsen', value: 'lower-saxony' },
            { label: 'Mecklenburg-Vorpommern', value: 'mecklenburg-vorpommern' },
            { label: 'Nordrhein-Westfalen', value: 'north-rhine-westphalia' },
            { label: 'Rheinland-Pfalz', value: 'rhineland-palatinate' },
            { label: 'Saarland', value: 'saarland' },
            { label: 'Sachsen', value: 'saxony' },
            { label: 'Sachsen-Anhalt', value: 'saxony-anhalt' },
            { label: 'Schleswig-Holstein', value: 'schleswig-holstein' },
            { label: 'Thüringen', value: 'thuringia' },
          ],
        },
        {
          label: 'United States of America',
          value: 'usa',
          regions: [
            { label: 'Alaska', value: 'AK' },
            { label: 'Texas', value: 'TX' },
            { label: 'Alabama', value: 'AL' },
            { label: 'Arkansas', value: 'AR' },
            { label: 'Arizona', value: 'AZ' },
            { label: 'California', value: 'CA' },
            { label: 'Colorado', value: 'CO' },
            { label: 'Connecticut', value: 'CT' },
            { label: 'District of Columbia', value: 'DC' },
            { label: 'Delaware', value: 'DE' },
            { label: 'Florida', value: 'FL' },
            { label: 'Georgia', value: 'GA' },
            { label: 'Hawaii', value: 'HI' },
            { label: 'Iowa', value: 'IA' },
            { label: 'Idaho', value: 'ID' },
            { label: 'Illinois', value: 'IL' },
            { label: 'Indiana', value: 'IN' },
            { label: 'Kansas', value: 'KS' },
            { label: 'Kentucky', value: 'KY' },
            { label: 'Louisiana', value: 'LA' },
            { label: 'Massachusetts', value: 'MA' },
            { label: 'Maryland', value: 'MD' },
            { label: 'Maine', value: 'ME' },
            { label: 'Michigan', value: 'MI' },
            { label: 'Minnesota', value: 'MN' },
            { label: 'Missouri', value: 'MO' },
            { label: 'Mississippi', value: 'MS' },
            { label: 'Montana', value: 'MT' },
            { label: 'NorthCarolina', value: 'NC' },
            { label: 'NorthDakota', value: 'ND' },
            { label: 'Nebraska', value: 'NE' },
            { label: 'NewHampshire', value: 'NH' },
            { label: 'NewJersey', value: 'NJ' },
            { label: 'NewMexico', value: 'NM' },
            { label: 'Nevada', value: 'NV' },
            { label: 'NewYork', value: 'NY' },
            { label: 'Ohio', value: 'OH' },
            { label: 'Oklahoma', value: 'OK' },
            { label: 'Oregon', value: 'OR' },
            { label: 'Pennsylvania', value: 'PA' },
            { label: 'RhodeIsland', value: 'RI' },
            { label: 'SouthCarolina', value: 'SC' },
            { label: 'SouthDakota', value: 'SD' },
            { label: 'Tennessee', value: 'TN' },
            { label: 'Texas', value: 'TX' },
            { label: 'Utah', value: 'UT' },
            { label: 'Virginia', value: 'VA' },
            { label: 'Vermont', value: 'VT' },
            { label: 'Washington', value: 'WA' },
            { label: 'Wisconsin', value: 'WI' },
            { label: 'WestVirginia', value: 'WV' },
            { label: 'Wyoming', value: 'WY' },
          ],
        },
      ],
    }),
    computed: {
      selectedCountry () {
        return this.countries.find(r => r.value === this.newCustomerData.country)
      },
      selectedCity () {
        return this.selectedCountry.regions.find(r => r.value === this.newCustomerData.region)
      },
    },
    mounted () {
      this.newCustomerData = this.customer
      this.$bus.$on('customer-update-run', this.updateProfile)
    },
    methods: {
      ...mapActions({
        update: 'customer/update',
      }),
      updateProfile () {
        this.update({ customer_id: this.customer.id, ...this.newCustomerData })
      },
    },
    beforeDestroy () {
      this.$bus.$off('customer-update-run', this.updateProfile)
    },
  }
</script>
