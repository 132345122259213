<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col>
          {{ $t('documents_status') }}
        </v-col>
        <v-col>
          {{ $t(documentsState) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <documents-list :documents="documents" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex'
  import DocumentsList from '@/components/Customers/Edit/Tabs/DocumentsTab/DocumentsList'
  export default {
    name: 'DocumentsTab',
    components: {
      DocumentsList,
    },
    props: {
      customer: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      loading: true,
      statesMapping: {
        0: 'not_uploaded',
        1: 'uploaded',
        2: 'verified',
      },
      documents: [],
    }),
    mounted () {
      this.loading = true
      this.loadDocuments({ customer_id: this.customer.id })
        .then(res => {
          this.documents = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      ...mapActions({
        loadDocuments: 'customer/documents/loadDocuments',
      }),
    },
    computed: {
      documentsState () {
        return this.statesMapping[this.customer.documents_state]
      },
    },
  }
</script>
