<template>
  <v-container
    id="customer-edit"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            {{ $t('edit_customer') }}
          </template>

          <v-tabs vertical>
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              {{ $t('profile') }}
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              {{ $t('documents') }}
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              {{ $t('greenhouse') }}
            </v-tab>
            <v-tab-item>
              <profile-tab
                v-if="customer.id"
                :customer="customer"
              />
            </v-tab-item>
            <v-tab-item>
              <documents-tab
                v-if="customer.id"
                :customer="customer"
              />
            </v-tab-item>
            <v-tab-item>
              <greenhouse-tab
                v-if="customer.id"
                :customer="customer"
              />
            </v-tab-item>
          </v-tabs>
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  min-width="150"
                  @click="submit"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import ProfileTab from '@/components/Customers/Edit/Tabs/ProfileTab'
  import DocumentsTab from '@/components/Customers/Edit/Tabs/DocumentsTab'
  import GreenhouseTab from '@/components/Customers/Edit/Tabs/GreenhouseTab'

  export default {
    name: 'CustomerItemEditForm',
    components: {
      ProfileTab,
      DocumentsTab,
      GreenhouseTab,
    },
    data: () => ({
      loading: false,
      customer: {
        id: null,
      },
    }),
    async mounted () {
      await this.loadItem({ customer_id: this.$route.params.id })
        .then(result => {
          this.customer = result.data
        })
    },
    methods: {
      ...mapActions({
        loadItem: 'customer/loadItem',
      }),
      submit () {
        this.$bus.$emit('customer-update-run')
      },
    },
  }
</script>
