<template>
  <v-list two-line>
    <v-list-item-group
      v-if="documents.length"
      active-class="pink--text"
      multiple
    >
      <document
        v-for="item in documents"
        :key="item.id"
        :item="item"
        @click.native="openDialog(item)"
      />
    </v-list-item-group>
    <template v-else>
      <v-list-item>
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-container class="py-0">
              <v-row>
                {{ $t('no_documents_uploaded_message') }}
              </v-row>
            </v-container>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
    <view-document-modal
      ref="document_view_modal"
      :item="active_item"
    />
  </v-list>
</template>
<script>
  import Document from '@/components/Customers/Edit/Tabs/DocumentsTab/Document'
  import ViewDocumentModal from '@/components/Customers/Edit/Tabs/DocumentsTab/ViewDocumentModal'
  export default {
    name: 'DocumentsList',
    components: {
      Document,
      ViewDocumentModal,
    },
    props: {
      documents: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      active_item: {},
    }),
    methods: {
      openDialog (item) {
        this.active_item = item
        this.$refs.document_view_modal.openDialog()
      },
    },
  }
</script>
